import React from 'react';

import Layout from '../components/layout';

export default function History() {
      return (
            <Layout subject="History">
                  History
            </Layout>
      )
}